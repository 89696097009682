import Section, { SECTION_VARIANTS } from 'components/Section'
import { useMemoizedContentGetter } from 'utils/contentful'
import { useViewportSmallerThan } from 'utils/viewport'
import { BREAKPOINTS } from 'constants/viewport'
import { Grid, Box } from '@achieve/ascend'
import SlideOnVisible from 'components/SlideOnVisible/SlideOnVisible'
import { LinkButton, MediaImageStandard, Typography } from 'components/Contentful'
import {
  SLIDE_ON_VISIBLE_EASING_DEFAULT,
  SLIDE_ON_VISIBLE_DURATION_DEFAULT,
  SLIDE_ON_VISIBLE_TIMEOUT_DEFAULT,
} from 'constants/animation'
import { Eyebrow } from 'components/Eyebrow'
import { BLOCKS } from '@contentful/rich-text-types'
import { ANCHOR_POINTS, AchieveBadge } from 'components/AchieveBadge'

import styles from './ARCloser.module.scss'
import classNames from 'classnames'
import AppStoreButtons from 'components/AppStoreButtons/AppStoreButtons'
import { IconCheckMark } from 'components/Svgs/IconCheckMark'

function ARCloserModule({
  config,
  title,
  eyebrow,
  itemList,
  button,
  mainImage,
  secondaryImage,
  badge,
  appStoreButtonFlag,
  appleButton,
  googleButton,
  appleImage,
  googleImage,
}) {
  const isMobile = useViewportSmallerThan(BREAKPOINTS.lg)
  const isSmallDesktop = useViewportSmallerThan(BREAKPOINTS.xl)
  const isXS = useViewportSmallerThan(BREAKPOINTS.sm)

  const badgeProps = {
    width: 90,
    height: 90,
    anchor: ANCHOR_POINTS.RIGHT_TOP,
    translateX: isSmallDesktop ? -230 : -320,
    translateY: -100,
  }

  const badgePropsMobile = {
    width: 80,
    height: 80,
    anchor: ANCHOR_POINTS.RIGHT_TOP,
    translateX: isXS ? -40 : 50,
    translateY: -10,
  }

  return (
    <Section
      contain={false}
      variant={config?.jsonContent?.sectionVariant ?? SECTION_VARIANTS.neutralGrey8}
      className={styles['section']}
    >
      <Grid
        container
        className={classNames(styles['pd-item'], (isMobile && badge && styles['pd-spacing']) ?? '')}
        direction="row"
      >
        {!isMobile && (
          <Grid item xxs={12} lg={6} className={styles['pd-item-panel-left']}>
            <Box className={styles['pd-item-brand']}>
              {mainImage && (
                <SlideOnVisible
                  duration={SLIDE_ON_VISIBLE_DURATION_DEFAULT}
                  timeout={SLIDE_ON_VISIBLE_TIMEOUT_DEFAULT}
                  easing={SLIDE_ON_VISIBLE_EASING_DEFAULT}
                >
                  <div>
                    <MediaImageStandard
                      alt={mainImage?.mediaAltText}
                      content={mainImage?.mediaContent}
                      className={styles['pd-item-image']}
                      width="290"
                      height="368"
                      layout="fixed"
                      data-testid="ar-closer-main-image"
                    />
                  </div>
                </SlideOnVisible>
              )}
              <Box className={styles['pd-item-card-image']}>
                {badge && (
                  <SlideOnVisible
                    duration={SLIDE_ON_VISIBLE_DURATION_DEFAULT}
                    timeout={SLIDE_ON_VISIBLE_TIMEOUT_DEFAULT}
                    easing={SLIDE_ON_VISIBLE_EASING_DEFAULT}
                  >
                    <div>
                      <AchieveBadge
                        content={badge?.mediaContent}
                        {...badgeProps}
                        data-testid="ar-closer-badge"
                      />
                    </div>
                  </SlideOnVisible>
                )}
                {secondaryImage && (
                  <SlideOnVisible
                    duration={SLIDE_ON_VISIBLE_DURATION_DEFAULT}
                    timeout={350}
                    easing={SLIDE_ON_VISIBLE_EASING_DEFAULT}
                  >
                    <div className={styles['pd-item-subimage-container']}>
                      <MediaImageStandard
                        content={secondaryImage?.mediaContent}
                        className={styles['pd-item-subimage']}
                        data-testid="ar-closer-sub-image"
                      />
                    </div>
                  </SlideOnVisible>
                )}
              </Box>
            </Box>
          </Grid>
        )}
        <Grid item xs={12} lg={6} className={styles['pd-item-panel-right']}>
          <SlideOnVisible
            duration={SLIDE_ON_VISIBLE_DURATION_DEFAULT}
            timeout={SLIDE_ON_VISIBLE_TIMEOUT_DEFAULT}
            easing={SLIDE_ON_VISIBLE_EASING_DEFAULT}
          >
            <Box className={styles['pd-item-info']}>
              {isMobile && badge && (
                <SlideOnVisible
                  duration={SLIDE_ON_VISIBLE_DURATION_DEFAULT}
                  timeout={SLIDE_ON_VISIBLE_TIMEOUT_DEFAULT}
                  easing={SLIDE_ON_VISIBLE_EASING_DEFAULT}
                >
                  <div>
                    <AchieveBadge content={badge?.mediaContent} {...badgePropsMobile} />
                  </div>
                </SlideOnVisible>
              )}
              {eyebrow && <Eyebrow className={styles['eyebrow']} content={eyebrow?.textContent} />}
              {title && (
                <Typography
                  className={classNames('section-title', styles['pd-item-title'])}
                  content={title?.textContent}
                  fontWeight="medium"
                />
              )}

              {isMobile && secondaryImage && (
                <SlideOnVisible
                  duration={SLIDE_ON_VISIBLE_DURATION_DEFAULT}
                  timeout={350}
                  easing={SLIDE_ON_VISIBLE_EASING_DEFAULT}
                >
                  <div className={styles['pd-item-subimage-mobile-container']}>
                    <MediaImageStandard
                      content={secondaryImage?.mobileMediaContent ?? secondaryImage?.mediaContent}
                      className={styles['pd-item-subimage']}
                    />
                  </div>
                </SlideOnVisible>
              )}

              {itemList?.gridItemsCollection?.items?.length > 0 && (
                <ul className={styles['pd-item-list']}>
                  {itemList?.gridItemsCollection?.items.map((icon, index) => {
                    return (
                      <li key={`item-${index}`} className={styles['icon-list-item']}>
                        <Grid className={styles['icon-item']} container direction="row">
                          <div>
                            <IconCheckMark title="Achieve checkmark icon" height={24} width={24} />
                          </div>
                          <div>
                            <Typography
                              content={icon?.textContent}
                              data-testid="ar-icon-list-item"
                              variant="bodySm"
                            />
                          </div>
                        </Grid>
                      </li>
                    )
                  })}
                </ul>
              )}

              {appStoreButtonFlag ? (
                <AppStoreButtons
                  appleButton={appleButton}
                  googleButton={googleButton}
                  appleImage={appleImage}
                  googleImage={googleImage}
                />
              ) : (
                button && (
                  <Box>
                    <LinkButton
                      track={{
                        ...{
                          click_id:
                            button?.linkText?.json?.content[0]?.content[0]?.value || 'no-click-id',
                          nav_link_section: 'Closer CTA',
                          list_name: 'Home Hero CTA click',
                          click_text: button?.linkText?.json?.content[0]?.content[0]?.value,
                          track_event: 'internal_campaign_click',
                          event_action: 'button_click',
                          event_type: 'button_click',
                        },
                        ...button?.event,
                      }}
                      typographicOptions={{
                        variantOverride: { [BLOCKS.PARAGRAPH]: 'bodyLg' },
                        fontWeight: 'bold',
                      }}
                      variant="contained"
                      color="secondary"
                      content={button}
                      className={styles['cta-button']}
                      data-testid="ar-closer-cta"
                    />
                  </Box>
                )
              )}
            </Box>
          </SlideOnVisible>
        </Grid>
      </Grid>
    </Section>
  )
}

function ARCloser({ content }) {
  let contentToGet = [
    'config',
    'title',
    'eyebrow',
    'itemList',
    'button',
    'mainImage',
    'secondaryImage',
    'badge',
  ]

  if (content?.fields?.additionalConfiguration?.jsonContent?.appStoreButtons)
    contentToGet = [...contentToGet, 'image_apple', 'image_google', 'button_apple', 'button_google']

  const {
    config,
    title,
    eyebrow,
    itemList,
    button,
    mainImage,
    secondaryImage,
    badge,
    image_apple,
    image_google,
    button_apple,
    button_google,
  } = useMemoizedContentGetter(content, contentToGet)

  return (
    <ARCloserModule
      config={config}
      title={title}
      eyebrow={eyebrow}
      itemList={itemList}
      button={button}
      mainImage={mainImage}
      secondaryImage={secondaryImage}
      badge={badge}
      appStoreButtonFlag={content?.fields?.additionalConfiguration?.jsonContent?.appStoreButtons}
      appleImage={image_apple}
      googleImage={image_google}
      appleButton={button_apple}
      googleButton={button_google}
    />
  )
}

export { ARCloser, ARCloserModule }
export default ARCloser
