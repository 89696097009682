import * as React from 'react'
export function IconCheckMark({ title, height = 24, width = 24, ...props }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} fill="none" {...props}>
      <title>{title}</title>
      <path
        stroke="#68D6F2"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
        d="M17.333 8 10 15.333 6.667 12"
      />
      <rect
        width={22.5}
        height={22.5}
        x={0.75}
        y={0.75}
        stroke="#fff"
        strokeWidth={1.5}
        rx={11.25}
      />
    </svg>
  )
}
export default IconCheckMark
